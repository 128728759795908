@font-face {
  font-family: 'Brandon';
  src: local('Brandon'), url("./fonts/brandon.otf") format('truetype');
}
/* html {
  overflow: hidden;
} */
/* body {
  overscroll-behavior: contain;
  bottom: 0;
  height: 100%;
  overflow: auto;
  margin: 0;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
